






import Vue from 'vue'
import Logout from '@/components/Logout.vue'

export default Vue.extend({
  name: 'LogoutPage',
  components: {
    Logout,
  },
})
